export const Newsphotos = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716203946/islaheaam/gallery/news/lbq4fzdtluvydr9b3g0i.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671456562/islaheaam/gallery/news/gallery5_gfxjor.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671456562/islaheaam/gallery/news/IMG-20210418-WA0238_n8pw25.jpg",
    width: 4,
    height: 3,
  },
];

export const NewsphotosHD = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716203946/islaheaam/gallery/news/lbq4fzdtluvydr9b3g0i.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671456562/islaheaam/gallery/news/gallery5_gfxjor.jpg",
    width: 746,
    height: 345,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671456562/islaheaam/gallery/news/IMG-20210418-WA0238_n8pw25.jpg",
    width: 760,
    height: 1051,
  },
];
