export const Eventphotos = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1716220718/islaheaam/gallery/events/pqjkjjdxsqmtannpfnvz.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1716202952/islaheaam/gallery/events/czcm6nzcbfqvxzqxkylv.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1716202957/islaheaam/gallery/events/xvfoiigicku5lrwk8xah.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1716202957/islaheaam/gallery/events/cnhc9onydwldyh39vd2o.jpg",
    width: 4,
    height: 3,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439862/islaheaam/gallery/events/gallery8_l8jxfx.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439860/islaheaam/gallery/events/gallery2_a8cp3x.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439859/islaheaam/gallery/events/gallery5_npsew2.jpg",
    width: 4,
    height: 3,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439821/islaheaam/gallery/events/about_hmn0u4.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439816/islaheaam/gallery/events/about-page3_cjz8um.jpg",
    width: 4,
    height: 2,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439815/islaheaam/gallery/events/about-page2_qhkkeu.jpg",
    width: 1,
    height: 1,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.8,c_scale/v1671439813/islaheaam/gallery/events/about-page4_u5ttli.jpg",
    width: 1,
    height: 1,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439522/islaheaam/gallery/events/IMG_20220708_111900_erfij7.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.6,c_scale/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0033_k2kakp.jpg",
    width: 4,
    height: 3,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0018_rm11nw.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.5,c_scale/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0014_gytsis.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0032_rfkx8z.jpg",
    width: 4,
    height: 3,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0023_a4oooj.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0017_owfn1g.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0019_g4ih2s.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
];

export const EventphotosHD = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716220718/islaheaam/gallery/events/pqjkjjdxsqmtannpfnvz.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716202952/islaheaam/gallery/events/czcm6nzcbfqvxzqxkylv.jpg",
    width: 4,
    height: 3,
    id: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716202957/islaheaam/gallery/events/xvfoiigicku5lrwk8xah.jpg",
    width: 4,
    height: 3,
    id: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716202957/islaheaam/gallery/events/cnhc9onydwldyh39vd2o.jpg",
    width: 4,
    height: 3,
    id: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439862/islaheaam/gallery/events/gallery8_l8jxfx.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439860/islaheaam/gallery/events/gallery2_a8cp3x.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439859/islaheaam/gallery/events/gallery5_npsew2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439821/islaheaam/gallery/events/about_hmn0u4.jpg",
    width: 4,
    height: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439816/islaheaam/gallery/events/about-page3_cjz8um.jpg",
    width: 4,
    height: 2,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439815/islaheaam/gallery/events/about-page2_qhkkeu.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439813/islaheaam/gallery/events/about-page4_u5ttli.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439522/islaheaam/gallery/events/IMG_20220708_111900_erfij7.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0033_k2kakp.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0018_rm11nw.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0014_gytsis.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0032_rfkx8z.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439467/islaheaam/gallery/events/IMG-20221219-WA0023_a4oooj.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0017_owfn1g.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439466/islaheaam/gallery/events/IMG-20221219-WA0019_g4ih2s.jpg",
    width: 4,
    height: 3,
  },
];
