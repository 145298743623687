import axios from "axios";

const sendEmail = (state) => async (dispatch) => {
  try {
    const { data } = await axios.post("/api/contact/sendemail", {
      state,
    });
    dispatch({ type: "EMAIL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "EMAIL_FAIL", payload: error.message });
  }
};

export { sendEmail };
