import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer class="footer footer-2">
        <div class="footer-middle">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <div class="widget widget-about">
                  <div class="footer-logo-div mb-2">
                    <img
                      src="logo.png"
                      class="footer-logo"
                      alt="Footer Logo"
                      width="105"
                      height="25"
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <h6 style={{ marginBottom: "0", marginRight: "5px" }}>
                        ISLAHEAAM INTER COLLEGE
                      </h6>
                      <small>( Affiliated To U.P Board )</small>
                    </div>
                  </div>
                  <p class="text-justify">
                    Islaheaam Inter College provides a positive, vibrant and
                    inspiring environment where students are valued and
                    encouraged to succeed in becoming life-long learners.
                  </p>

                  <div class="widget-about-info">
                    <div class="row">
                      <div class="col-sm-6 col-md-4">
                        <span class="widget-about-title">
                          Got Question? Call us 24/7
                        </span>
                        <a href="tel:9917164462">+91 9917164462</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-2">
                <div class="widget">
                  <h4 class="widget-title">Information</h4>

                  <ul class="widget-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">About</Link>
                    </li>
                    <li>
                      <Link to="/admission">Admission</Link>
                    </li>
                    <li>
                      <Link to="/staff">Staff</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6 gallery-width">
                <div class="widget footer-widget">
                  <h4 class="widget-title">Gallery</h4>

                  <div class="gallery-wrap">
                    <Link to="/photo-gallery">
                      <div className="flex justify-content-center flex-wrap">
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439911/islaheaam/gallery/general/gallery4_v4gigm.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439859/islaheaam/gallery/events/gallery5_npsew2.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439912/islaheaam/gallery/general/gallery3_a1qvmu.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439860/islaheaam/gallery/events/gallery2_a8cp3x.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439862/islaheaam/gallery/events/gallery8_l8jxfx.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439273/islaheaam/gallery/games/IMG_20221031_111532_nqudex.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439921/islaheaam/gallery/general/gallery1_byycqj.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="gallery-img">
                          <img
                            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1671439562/islaheaam/gallery/tour/IMG-20221219-WA0027_m9zeva.jpg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom">
          <div class="container">
            <p class="footer-copyright">
              Copyright © 2024 ISLAHEAAM INTER COLLEGE. All Rights Reserved.
            </p>

            {/* <ul class="footer-menu">
              <li>
                <a href="#">Terms Of Use</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul> */}

            <div class="social-icons social-icons-color">
              <span class="social-label">Social Media</span>
              <a
                href="https://www.facebook.com/IslaheaamInterCollege"
                class="social-icon social-facebook"
                rel="noreferrer"
                title="Facebook"
                target="_blank"
              >
                <i class="icon-facebook-f"></i>
              </a>
              {/* <a
                href="#"
                class="social-icon social-twitter"
                title="Twitter"
                target="_blank"
              >
                <i class="icon-twitter"></i>
              </a> */}
              <a
                href="https://www.instagram.com/islaheaamintercollege"
                class="social-icon social-instagram"
                title="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <i class="icon-instagram"></i>
              </a>
              <a
                href="https://youtube.com/@islaheaamintercollege"
                class="social-icon social-youtube"
                title="Youtube"
                rel="noreferrer"
                target="_blank"
              >
                <i class="icon-youtube"></i>
              </a>
              {/* <a
                href="#"
                class="social-icon social-pinterest"
                title="Pinterest"
                target="_blank"
              >
                <i class="icon-pinterest"></i>
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
