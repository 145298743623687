import React from "react";
import { Link } from "react-router-dom";

const Events = () => {
  return (
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="entry-container max-col-2" data-layout="fitRows">
              <div class="entry-item col-sm-4">
                <h3 style={{ marginBottom: "0.5rem" }} class="widget-title">
                  Downloads
                </h3>
                <div style={{ padding: "10px" }}>
                  <ul>
                    {/* <li>
                      <a href="#">&#62; Brochure</a>
                    </li> */}
                    <li>
                      <a
                        href="\RegistrationForm 9,10.pdf"
                        download="Registration form (IX & X)"
                      >
                        &#62; Admission Form (Class IX & X)
                      </a>
                    </li>
                    <li>
                      <a
                        href="\RegistrationForm 11,12.pdf"
                        download="Registration form (XI & XII)"
                      >
                        &#62; Admission Form (Class XI & XII)
                      </a>
                    </li>
                  </ul>
                </div>
                <h3 style={{ marginBottom: "0.5rem" }} class="widget-title">
                  News and Events
                </h3>

                <div style={{ padding: "10px" }}>
                  <ul>
                    <li>
                      <Link to="/admission">
                        &#62; Admission Open
                        {/* <br /> ( 26-01-2023 ) */}
                      </Link>
                    </li>
                    {/* <li>
                      <a href="#">&#62; Secont Event</a>
                    </li> */}
                  </ul>
                </div>
                {/* <div id="marqu" style={{ padding: "10px" }}>
                  <marquee
                    width="100%"
                    direction="up"
                    height="150px"
                    scrollamount="3"
                  >
                    <a href="#">
                      &#62; This is a sample scrolling text that has scrolls
                      texts to down.
                    </a>
                    <br />
                    <a href="#"> &#62; Second event</a>
                  </marquee>
                </div> */}
              </div>

              <div class="entry-item col-sm-8">
                <h3 class="widget-title">About Us</h3>
                <article class="entry entry-grid">
                  <figure class="entry-media">
                    <img
                      src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671434296/islaheaam/homepage/about_vpyiid.jpg"
                      alt=" desc"
                    />
                  </figure>

                  <div class="entry-body">
                    <div class="entry-title">
                      <h4 class="font-color">ISLAHEAAM INTER COLLEGE</h4>
                    </div>

                    <div class="entry-content text-justify">
                      <p>
                        Islaheaam Inter College was opened to represent Sir
                        Sayyad Ahmad Khan legacy and purpose of providing
                        education to all especially our community, so that it
                        could move forward with the changing time by using
                        education as their shield and weapon...
                      </p>
                      <Link to="/about" class="read-more">
                        Continue Reading
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>

          <aside class="col-lg-3">
            <div class="sidebar">
              <div class="widget">
                <h3 class="widget-title">School Management</h3>

                <ul class="posts-list">
                  <li>
                    <figure>
                      <Link to="/staff">
                        <img
                          src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.7,c_scale/v1671435000/islaheaam/staff/photo_bg4dqp.jpg"
                          alt="post"
                        />
                      </Link>
                    </figure>

                    <div>
                      <p class="management-name">
                        <Link to="/staff">Abdul Khaliq</Link>
                      </p>
                      <h4>Ex-President</h4>
                    </div>
                  </li>

                  <li>
                    <figure>
                      <Link to="/staff">
                        <img
                          src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716201404/islaheaam/staff/j444irpngeffxgfg5kvj.jpg"
                          alt="post"
                        />
                      </Link>
                    </figure>

                    <div>
                      <p class="management-name">
                        <Link to="/staff">Dr. Shafiq Ahmad</Link>
                      </p>
                      <h4>President</h4>
                    </div>
                  </li>

                  <li>
                    <figure>
                      <Link to="/staff">
                        <img
                          src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.6,c_scale/v1671435615/islaheaam/staff/IMG_20221219_112231_jkmpvj.jpg"
                          alt="post"
                        />
                      </Link>
                    </figure>

                    <div>
                      <p class="management-name">
                        <Link to="/staff">Shabnam Jahan</Link>
                      </p>
                      <h4>Principal</h4>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="flex justify-content-center">
                <div class="btn-wrap">
                  <Link to="/staff" class="btn btn-outline-primary btn-round">
                    <span>View All</span>
                    <i class="icon-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default Events;
