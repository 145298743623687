import React from "react";

const Message = () => {
  return (
    <div class="about-testimonials bg-light-2 pt-6 pb-6">
      <div class="container">
        <h2 class="title text-center mb-3">Message From Principal</h2>
        <blockquote class="testimonial text-center">
          <div>
            <img
              src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.6,c_scale/v1671435615/islaheaam/staff/IMG_20221219_112231_jkmpvj.jpg"
              alt="user"
            />
          </div>
          <p class="mb-2">
            “ Success comes only to those who have a goal in life and are honest
            towards their goal. And take true determination to achieve their
            goal and for this they keep trying continuously. Any success starts
            with trying. If you want to be successful, you have to try.... ”
          </p>
          <cite>
            Shabnam Jahan
            <span>Principal</span>
          </cite>
        </blockquote>
      </div>
    </div>
  );
};

export default Message;
