import React from "react";
import { Link } from "react-router-dom";

const Admission = () => {
  return (
    <div className="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">
            Admission
            <span style={{ fontSize: "15px" }}>
              Admissions Open for 2024-25{" "}
            </span>
          </h1>
        </div>
      </div>
      <div class="container mt-4 mb-4">
        <p className="mt-4 text-justify">
          Islaheaam Inter College accepts new admissions in all the Classes 6 to
          12 on the basis of an admission test and interview. Admissions in the
          classes are subject to the availability of seats. Only students
          scoring 33% or more marks in previous classes can apply for admission
          in Islaheaam Inter College. Application forms and prospectus are made
          available in the month of April followed by entrance tests.
        </p>
        <h4 className="mt-4">Procedure & Eligibility:</h4>
        <p className="text-justify">
          Parents desiring to admit their wards in this school are required to
          register them through a prescribed form available at the school
          reception or at <Link to="/">downloads section</Link>.
          <br />
          <br />
          The following documents are required to be submitted at the time of
          registration/admission:
          <div style={{ padding: "10px" }} className="text-justify">
            <li>
              Photocopy of the Date of Birth Certificate of the child- from the
              “Death & Birth Registrar” or competent authority to issue the
              same.
            </li>
            <li>Mark sheet/Progress Report of the previous class attended.</li>
            <li>
              Original copy of the Transfer Certificate & Character Certificate
              of the last school attended.
            </li>
            <li> Four passport size coloured photographs of the child.</li>
          </div>
        </p>
      </div>
    </div>
  );
};

export default Admission;
