export const Gamesphotos = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439304/islaheaam/gallery/games/IMG_20221031_110938_byecnz.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439286/islaheaam/gallery/games/IMG_20221031_111537_gi9al5.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439287/islaheaam/gallery/games/IMG_20221031_110732_irc0gs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439273/islaheaam/gallery/games/IMG_20221031_111532_nqudex.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439268/islaheaam/gallery/games/IMG_20221031_111141_bzmft2.jpg",
    width: 4,
    height: 3,
  },
];
export const GamesphotosHD = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439304/islaheaam/gallery/games/IMG_20221031_110938_byecnz.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439286/islaheaam/gallery/games/IMG_20221031_111537_gi9al5.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439287/islaheaam/gallery/games/IMG_20221031_110732_irc0gs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439273/islaheaam/gallery/games/IMG_20221031_111532_nqudex.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439268/islaheaam/gallery/games/IMG_20221031_111141_bzmft2.jpg",
    width: 4,
    height: 3,
  },
];
