import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { emailsendreducer } from "./reducer/itemsDataReducer";

const initialState = {};

// all reducers
const allReducers = combineReducers({
  emailSend: emailsendreducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  allReducers,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
