import React from "react";

import Premises from "./imageGallery/premises";
import Events from "./imageGallery/events";
import Games from "./imageGallery/games";
import Tour from "./imageGallery/tour";
import News from "./imageGallery/news";

const PhotoGallery = () => {
  function Scroll(element) {
    document.getElementById(element).scrollIntoView();
  }
  return (
    <div className="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('images/page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">Gallery</h1>
        </div>
      </div>

      <div class="accordion accordion-rounded mt-3" id="accordion-5">
        <div class="card card-box card-sm bg-light">
          <div
            class="card-header"
            onClick={() => Scroll("heading5-1")}
            id="heading5-1"
          >
            <h2 class="card-title">
              <a
                role="button"
                data-toggle="collapse"
                href="#collapse5-1"
                aria-expanded="true"
                aria-controls="collapse5-1"
              >
                School Premises
              </a>
            </h2>
          </div>
          <div
            id="collapse5-1"
            class="collapse show"
            aria-labelledby="heading5-1"
            data-parent="#accordion-5"
          >
            <div class="card-body">
              <Premises />
            </div>
          </div>
        </div>

        <div class="card card-box card-sm bg-light">
          <div
            class="card-header"
            onClick={() => Scroll("heading5-2")}
            id="heading5-2"
          >
            <h2 class="card-title">
              <a
                class="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse5-2"
                aria-expanded="false"
                aria-controls="collapse5-2"
              >
                School's Event
              </a>
            </h2>
          </div>
          <div
            id="collapse5-2"
            class="collapse"
            aria-labelledby="heading5-2"
            data-parent="#accordion-5"
          >
            <div class="card-body">
              <Events />
            </div>
          </div>
        </div>

        <div class="card card-box card-sm bg-light">
          <div
            class="card-header"
            onClick={() => Scroll("heading5-3")}
            id="heading5-3"
          >
            <h2 class="card-title">
              <a
                class="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse5-3"
                aria-expanded="false"
                aria-controls="collapse5-3"
              >
                Sports
              </a>
            </h2>
          </div>
          <div
            id="collapse5-3"
            class="collapse"
            aria-labelledby="heading5-3"
            data-parent="#accordion-5"
          >
            <Games />
          </div>
        </div>

        <div class="card card-box card-sm bg-light">
          <div
            class="card-header"
            onClick={() => Scroll("heading5-4")}
            id="heading5-4"
          >
            <h2 class="card-title">
              <a
                class="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse5-4"
                aria-expanded="false"
                aria-controls="collapse5-4"
              >
                Tour
              </a>
            </h2>
          </div>
          <div
            id="collapse5-4"
            class="collapse"
            aria-labelledby="heading5-4"
            data-parent="#accordion-5"
          >
            <Tour />
          </div>
        </div>
        <div class="card card-box card-sm bg-light">
          <div
            class="card-header"
            onClick={() => Scroll("heading5-5")}
            id="heading5-5"
          >
            <h2 class="card-title">
              <a
                class="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse5-5"
                aria-expanded="false"
                aria-controls="collapse5-5"
              >
                News
              </a>
            </h2>
          </div>
          <div
            id="collapse5-5"
            class="collapse"
            aria-labelledby="heading5-5"
            data-parent="#accordion-5"
          >
            <News />
          </div>
        </div>
      </div>

      {/* <Gallery
        style={{ textAlign: "center" }}
        photos={photos}
        onClick={openLightbox}
      /> */}
      {/* <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway> */}

      {/* <div class="page-content mt-4">
        <div class="container">
          <div class="entry-container max-col-4 gallery-psw">
            <div class="entry-item lifestyle shopping col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery1.jpg" target="_blank">
                    <img src="gallery/gallery1.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item lifestyle col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery2.jpg" target="_blank">
                    <img src="gallery/gallery2.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item fashion lifestyle col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery3.jpg" target="_blank">
                    <img src="gallery/gallery3.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item travel col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery4.jpg" target="_blank">
                    <img src="gallery/gallery4.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item travel col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery5.jpg" target="_blank">
                    <img src="gallery/gallery5.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item hobbies col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery6.jpg" target="_blank">
                    <img src="gallery/gallery6.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item travel col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery7.jpg" target="_blank">
                    <img src="gallery/gallery7.jpg" alt=" desc" />
                  </a>
                </figure>
              </article>
            </div>

            <div class="entry-item travel col-sm-6 col-md-4 col-lg-3">
              <article class="entry entry-grid text-center">
                <figure class="entry-media">
                  <a href="gallery/gallery8.jpg" target="_blank">
                    <img src="gallery/gallery8.jpg" alt="desc" />
                  </a>
                </figure>
              </article>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PhotoGallery;
