function emailsendreducer(state = {}, action) {
  switch (action.type) {
    case "EMAIL_SUCCESS":
      return { loading: true, response: action.payload };
    case "EMAIL_ERROR":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { emailsendreducer };
