import React from "react";
import Carousel from "./homepage/carousel";
import Events from "./homepage/events";
import Facilities from "./homepage/facilities";
import Message from "./homepage/message";

const Homepage = () => {
  return (
    <div className="main">
      <Carousel />
      <Events />
      <Facilities />
      <Message />
    </div>
  );
};

export default Homepage;
