import React from "react";
import { Link } from "react-router-dom";

const Staff = () => {
  return (
    <div className="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">Management And Faculty</h1>
        </div>
      </div>

      <div class="container mt-4 mb-4">
        <p class="text-justify">
          The faculty at Islaheaam Inter College are a team of well qualified,
          enthusiastic educators who build trusting relationships with students
          in order to create a safe, positive, and productive learning
          environment. They are compassionate persons, dedicated to teaching and
          having a sensitivity to student differences, particularly with
          learners and their learning needs.
          <br />
          <br />
          Teachers get together every week on Saturdays for different meetings,
          Continuous Professional Development Programmes (CPDs) and also to
          assist students who require their special attention. Such meetings
          help teachers to understand each other and the student.
        </p>
      </div>
      <div class="page-content">
        <div class="flex justify-content-center flex-wrap mb-4">
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.5,c_scale/v1671435000/islaheaam/staff/photo_bg4dqp.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Abdul Khaliq</Link>
              </h3>
              <div class="entry-content">
                <p>Ex-President</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716201404/islaheaam/staff/j444irpngeffxgfg5kvj.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Dr. Shafiq Ahmad</Link>
              </h3>
              <div class="entry-content">
                <p>President</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.3,c_scale/v1671435615/islaheaam/staff/IMG_20221219_112231_jkmpvj.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Shabnam Jahan</Link>
              </h3>
              <div class="entry-content">
                <p>Principal</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.9,c_scale/v1671434999/islaheaam/staff/photo3_b10hml.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Mohd Danish</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4/c_scale/v1671437049/islaheaam/staff/photo4_ae1yo0.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Husna</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671435008/islaheaam/staff/photo5_helj2y.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Raju Singh</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716202005/islaheaam/staff/fbwejlyu13aqqppehob5.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Iramshah Parveen</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.3,c_scale/v1671437058/islaheaam/staff/photo7_fnowvl.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Mahendra Singh</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671437770/islaheaam/staff/IMG_20221219_134139_hwfeea.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Shaziya Khatoon</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716201999/islaheaam/staff/fbf1b17bfugzpdedppbt.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Mantasha Parveen</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716202260/islaheaam/staff/nstvqsjahomzu5evug10.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Shubham Kumar</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>

          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1679908941/islaheaam/staff/1678802092850_vrst2a.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Aarti</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <div class="image-faculty">
              <img
                src="https://res.cloudinary.com/dujjhexjj/image/upload/v1716201992/islaheaam/staff/x8kuezxk45aqnndrnknm.jpg"
                alt=""
              />
            </div>
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="">Tabassum Parveen</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
