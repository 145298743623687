export const Premisesphotos = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1716202992/islaheaam/gallery/general/j3gyey2qi4osbbcsnyss.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1716202991/islaheaam/gallery/general/gcxdountmthyddhm2kfs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439767/islaheaam/gallery/general/scho_wqyprp.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439773/islaheaam/gallery/general/banner3_q1w117.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439762/islaheaam/gallery/general/school_om3gkq.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439706/islaheaam/gallery/general/IMG_20221126_083626_rs6mul.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439922/islaheaam/gallery/general/gallery7_ecqmg7.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439921/islaheaam/gallery/general/gallery1_byycqj.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.5,c_scale/v1671439912/islaheaam/gallery/general/gallery3_a1qvmu.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.6,c_scale/v1671439911/islaheaam/gallery/general/gallery4_v4gigm.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439698/islaheaam/gallery/general/IMG_20221126_083345_yexznk.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439695/islaheaam/gallery/general/IMG_20221126_083603_oyyw3t.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439693/islaheaam/gallery/general/IMG_20221126_083559_wiwdba.jpg",
    width: 4,
    height: 3,
  },
];

export const PremisesphotosHD = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716202992/islaheaam/gallery/general/j3gyey2qi4osbbcsnyss.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1716202991/islaheaam/gallery/general/gcxdountmthyddhm2kfs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439767/islaheaam/gallery/general/scho_wqyprp.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439773/islaheaam/gallery/general/banner3_q1w117.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439762/islaheaam/gallery/general/school_om3gkq.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439706/islaheaam/gallery/general/IMG_20221126_083626_rs6mul.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439922/islaheaam/gallery/general/gallery7_ecqmg7.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439921/islaheaam/gallery/general/gallery1_byycqj.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439912/islaheaam/gallery/general/gallery3_a1qvmu.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439911/islaheaam/gallery/general/gallery4_v4gigm.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439698/islaheaam/gallery/general/IMG_20221126_083345_yexznk.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439695/islaheaam/gallery/general/IMG_20221126_083603_oyyw3t.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439693/islaheaam/gallery/general/IMG_20221126_083559_wiwdba.jpg",
    width: 4,
    height: 3,
  },
];
