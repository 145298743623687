import React, { useCallback } from "react";

const Carousel = () => {
  const carous = useCallback((node) => {
    if (node !== null) {
      window.$(".carousel").carousel({
        interval: 3000,
      });
    }
  }, []);
  return (
    <div>
      <div class="intro-slider-container mb-6">
        <div
          ref={carous}
          id="homepagecarousels"
          class="carousel slide"
          data-interval="3000"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#homepagecarousels"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#homepagecarousels" data-slide-to="1"></li>
            <li data-target="#homepagecarousels" data-slide-to="2"></li>
            <li data-target="#homepagecarousels" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active" data-interval="3000">
              <div
                className="intro-slide"
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671433716/islaheaam/homepage/school_jqando.jpg')",
                }}
              ></div>
            </div>
            <div class="carousel-item" data-interval="3000">
              <div
                className="intro-slide"
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671433715/islaheaam/homepage/banner2_xxrrlh.jpg')",
                }}
              ></div>
            </div>
            <div class="carousel-item" data-interval="3000">
              <div
                className="intro-slide"
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671433715/islaheaam/homepage/banner3_mhgi21.jpg')",
                }}
              ></div>
            </div>
            <div class="carousel-item" data-interval="3000">
              <div
                className="intro-slide"
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671433716/islaheaam/homepage/scho_ru9dnx.jpg')",
                }}
              ></div>
            </div>
          </div>
        </div>
        {/* 
        <span class="slider-loader"></span> */}
      </div>
    </div>
  );
};

export default Carousel;
