import React from "react";
import { Link } from "react-router-dom";
import Message from "./homepage/message";

const About = () => {
  return (
    <div className="main">
      <div class="listing-img-container">
        <div class="black-opacity">
          <div style={{ textAlign: "center" }}>
            <h1 style={{ color: "white" }}>About Us</h1>
          </div>
        </div>
      </div>

      <div class="page-content pb-0 mt-3">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 mb-3 mb-lg-0" style={{ padding: "0 20px" }}>
              <h2 class="title font-color">Our Vision</h2>

              <p class="text-justify">
                Islaheaam Inter College encourages the development of the whole
                student. Our aim is to support learners in becoming curious,
                reflective and critical in their thinking as global citizens.
              </p>
            </div>

            <div class="col-lg-6" style={{ padding: "0 20px" }}>
              <h2 class="title font-color">Our Mission</h2>

              <p class="text-justify">
                Islaheaam Inter College provides a positive, vibrant and
                inspiring environment where students are valued and encouraged
                to succeed in becoming life-long learners.
              </p>
            </div>
          </div>

          <div class="mb-5"></div>

          <div class="mb-5"></div>
        </div>

        <div class="bg-light-2 pt-6 pb-5 res-py-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 mb-3 mb-lg-0">
                <h2 class="title" style={{ paddingTop: "15px" }}>
                  About Us
                </h2>

                <p class="lead text-primary mb-3 text-justify">
                  Islaheaam Inter College was opened to represent Sir Sayyad
                  Ahmad Khan legacy and purpose of providing education to all
                  especially our community, so that it could move forward with
                  the changing time by using education as their shield and
                  weapon.
                  <br />
                  <br />
                  It is an Educational Institute, which was established in 2015
                  and got it’s recognition for class 6 to 12 in 2016 as an
                  intermediate school. Currently it is providing education to
                  the children from class 6 to 12 and helping them preparing for
                  the challenges of life ahead.
                </p>
              </div>

              <div class="col-lg-6 offset-lg-1">
                <div class="about-images">
                  <img
                    src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.3,c_scale/v1671436417/islaheaam/about/about-page1_zjbdxe.jpg"
                    alt=""
                    class="about-img-front"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-light-2 pb-5 mb-6 mb-lg-8">
          <div class="container">
            <div class="row column-rev">
              <div class="col-lg-6">
                <div class="about-images">
                  <img
                    src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671436418/islaheaam/about/about-page3_cygqzx.jpg"
                    alt=""
                    class="about-img-front"
                  />
                </div>
              </div>
              <div class="col-lg-5 mb-3 mb-lg-0 offset-lg-1 pt-4">
                <p class="lead text-primary mb-3 text-justify">
                  Islaheaam Inter College, over the years, has come to symbolize
                  the, truest spirit of education to the task of maintaining an
                  excellent standard of education, not only in the academic
                  results, but also in various fields of extra Curricular
                  activities.
                  <br />
                  <br />
                  Any new technology likely to prove helpful to the students, is
                  made available by the School, and students are given an
                  opportunity to learn, in accordance with their individual
                  capability. For education, to be meaningful and rewarding, it
                  is essential that the students, staff and the parents work in
                  unison. The School Diary must be used as a means for achieving
                  this goal, to get the best from the School for your ward.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 class="title text-center mb-4">School Management</h2>
        <div class="flex justify-content-center flex-wrap mb-4">
          <div class="image-caro">
            <img
              src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.5,c_scale/v1671435000/islaheaam/staff/photo_bg4dqp.jpg"
              alt=""
            />
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="/staff">Abdul Khaliq</Link>
              </h3>
              <div class="entry-content">
                <p>President</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <img
              src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671435615/islaheaam/staff/IMG_20221219_112231_jkmpvj.jpg"
              alt=""
            />
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="/staff">Shabnam Jahan</Link>
              </h3>
              <div class="entry-content">
                <p>Principal</p>
              </div>
            </div>
          </div>
          <div class="image-caro">
            <img
              src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.9,c_scale/v1671434999/islaheaam/staff/photo3_b10hml.jpg"
              alt=""
            />
            <div class="entry-body text-center">
              <h3 class="entry-title mt-2">
                <Link to="/staff">Mohd Danish</Link>
              </h3>
              <div class="entry-content">
                <p>Faculty</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2"></div>

        <Message />
      </div>
    </div>
  );
};

export default About;
