export const Tourphotos = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.8,c_scale/v1671439561/islaheaam/gallery/tour/IMG-20221219-WA0028_l9fkgf.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.6,c_scale/v1671439562/islaheaam/gallery/tour/IMG-20221219-WA0027_m9zeva.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.7,c_scale/v1671439561/islaheaam/gallery/tour/IMG-20221219-WA0030_vbvbdz.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1679909511/islaheaam/gallery/tour/1678795492665_zs9hes.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1679909510/islaheaam/gallery/tour/1678795230506_cossbs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/w_0.1,c_scale/v1679909516/islaheaam/gallery/tour/1676431733137_cgzu7y.jpg",
    width: 4,
    height: 3,
  },
];

export const TourphotosHD = [
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439561/islaheaam/gallery/tour/IMG-20221219-WA0028_l9fkgf.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439562/islaheaam/gallery/tour/IMG-20221219-WA0027_m9zeva.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1671439561/islaheaam/gallery/tour/IMG-20221219-WA0030_vbvbdz.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1679909511/islaheaam/gallery/tour/1678795492665_zs9hes.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1679909510/islaheaam/gallery/tour/1678795230506_cossbs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dujjhexjj/image/upload/v1679909516/islaheaam/gallery/tour/1676431733137_cgzu7y.jpg",
    width: 4,
    height: 3,
  },
];
