import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import About from "./components/about";
import Admission from "./components/admission";
import Contact from "./components/contact";
import Error from "./components/error";
import Footer from "./components/footer";
import PhotoGallery from "./components/gallery";
import Homepage from "./components/homepage";
import Navbar from "./components/navbar";
import Staff from "./components/staff";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Homepage />} />

          <Route exact path="/about" element={<About />} />

          <Route exact path="/staff" element={<Staff />} />
          <Route exact path="/photo-gallery" element={<PhotoGallery />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/admission" element={<Admission />} />

          <Route exact path="/404" element={<Error />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>

        <Footer />
      </BrowserRouter>
      <button id="scroll-top" title="Back to Top">
        <i class="icon-arrow-up"></i>
      </button>
    </div>
  );
}

export default App;
