import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [classActive, setclassActive] = useState(false);

  function togglemenu() {
    if (classActive) {
      document.body.classList.remove("mmenu-active");
    } else {
      document.body.classList.add("mmenu-active");
    }
    setclassActive(!classActive);
    // document.body.classList.toggle("mmenu-active");
  }
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const [scroll, setScroll] = useState(false);
  // console.log(scroll);
  useEffect(() => {
    const updatePosition = () => {
      // console.log(window.scrollY);
      setScroll(window.scrollY > 300);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  return (
    <div>
      <header
        class="header header-7"
        style={{ height: scroll && size > 945 && "54px" }}
      >
        <div class={`header-middle sticky-header ${scroll && "fixed"}`}>
          <div class="container">
            <div class="header-left">
              {/* <button onClick={togglemenu} class="mobile-menu-toggler">
                <span class="sr-only">Toggle mobile menu</span>
                <i class="icon-bars"></i>
              </button> */}

              <Link
                style={{
                  fontWeight: "bolder",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
                to="/"
                class="logo"
              >
                <img src="logo.png" alt="Logo" class="nav-logo" />
                <div>
                  <h6
                    class="font-color"
                    style={{
                      marginBottom: "0",
                      marginLeft: "10px",
                    }}
                  >
                    ISLAHEAAM INTER COLLEGE
                  </h6>
                  <small style={{ marginBottom: "0", marginLeft: "10px" }}>
                    ( Affiliated to U.P Board )
                  </small>
                </div>
              </Link>
            </div>

            <div class="header-right">
              <button onClick={togglemenu} class="mobile-menu-toggler">
                <span class="sr-only">Toggle mobile menu</span>
                <i class="icon-bars"></i>
              </button>
              <nav class="main-nav">
                <ul class="menu sf-arrows">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/staff">Staff</Link>
                  </li>
                  <li>
                    <Link to="/admission">Admission</Link>
                  </li>
                  {/* <li>
                    <a href="#!" class="sf-with-ul">
                      Blog
                    </a>

                    <ul>
                      <li>
                        <a href="#!">XYZ</a>
                      </li>
                      <li>
                        <a href="#!">XYZ</a>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link to="/photo-gallery" class="">
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" class="">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {/* mobile */}
      <div onClick={togglemenu} className="mobile-menu-overlay"></div>
      <div class="mobile-menu-container">
        <div class="mobile-menu-wrapper">
          <span onClick={togglemenu} class="mobile-menu-close">
            <i class="icon-close"></i>
          </span>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="mobile-menu-tab"
              role="tabpanel"
              aria-labelledby="mobile-menu-link"
            >
              <nav class="mobile-nav">
                <ul class="mobile-menu">
                  <li onClick={togglemenu}>
                    <Link to="/">Home</Link>
                  </li>

                  <li onClick={togglemenu}>
                    <Link to="/about">About</Link>
                  </li>
                  <li onClick={togglemenu}>
                    <Link to="/staff">Staff</Link>
                  </li>
                  <li onClick={togglemenu}>
                    <Link to="/admission">Admission</Link>
                  </li>
                  {/* <li>
                    <a href="#!">Blog</a>
                    <ul>
                      <li onClick={togglemenu}>
                        <a href="#!">XYZ</a>
                      </li>
                      <li onClick={togglemenu}>
                        <a href="#!">XYZ</a>
                      </li>
                    </ul>
                  </li> */}
                  <li onClick={togglemenu}>
                    <Link to="/photo-gallery">Gallery</Link>
                  </li>
                  <li onClick={togglemenu}>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div onClick={togglemenu} class="social-icons">
            <a
              href="https://www.facebook.com/IslaheaamInterCollege"
              rel="noreferrer"
              class="social-icon"
              target="_blank"
              title="Facebook"
            >
              <i class="icon-facebook-f"></i>
            </a>
            {/* <a href="#" class="social-icon" target="_blank" title="Twitter">
              <i class="icon-twitter"></i>
            </a> */}
            <a
              href="https://www.instagram.com/islaheaamintercollege"
              class="social-icon"
              rel="noreferrer"
              target="_blank"
              title="Instagram"
            >
              <i class="icon-instagram"></i>
            </a>
            <a
              href="https://youtube.com/@islaheaamintercollege"
              rel="noreferrer"
              class="social-icon"
              target="_blank"
              title="Youtube"
            >
              <i class="icon-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
