import React from "react";
import { Link } from "react-router-dom";

const Facilities = () => {
  return (
    <div>
      <h2 class="title text-center mb-4">Events</h2>
      <div class="flex justify-content-center flex-wrap mb-4">
        <div class="image-caro">
          <img
            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439859/islaheaam/gallery/events/gallery5_npsew2.jpg"
            alt=""
          />
          <div class="entry-body text-center">
            <h3 class="entry-title mt-2">
              <Link to="/photo-gallery">Competitions</Link>
            </h3>
            <div class="entry-content">{/* <p>President</p> */}</div>
          </div>
        </div>
        <div class="image-caro">
          <img
            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439522/islaheaam/gallery/events/IMG_20220708_111900_erfij7.jpg"
            alt=""
          />
          <div class="entry-body text-center">
            <h3 class="entry-title mt-2">
              <Link to="/photo-gallery">Plantation</Link>
            </h3>
            <div class="entry-content">{/* <p>Principal</p> */}</div>
          </div>
        </div>
        <div class="image-caro">
          <img
            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.4,c_scale/v1671439562/islaheaam/gallery/tour/IMG-20221219-WA0027_m9zeva.jpg"
            alt=""
          />
          <div class="entry-body text-center">
            <h4 class="entry-title mt-2">
              <Link to="/photo-gallery">Tour</Link>
            </h4>
            <div class="entry-content">{/* <p>Principal</p> */}</div>
          </div>
        </div>
        <div class="image-caro">
          <img
            src="https://res.cloudinary.com/dujjhexjj/image/upload/w_0.2,c_scale/v1671439287/islaheaam/gallery/games/IMG_20221031_110732_irc0gs.jpg"
            alt=""
          />
          <div class="entry-body text-center">
            <h6 class="entry-title mt-2">
              <Link to="/photo-gallery">Sports</Link>
            </h6>
            <div class="entry-content">{/* <p>Principal</p> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
