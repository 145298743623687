import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail } from "../actions/itemsDataActions";
import popup from "./popup";

const Contact = () => {
  const sendBtn = useRef();
  const [isdisable, setisdisable] = useState(false);
  const [State, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    mobile: "",
  });
  const Inputchange = (event) => {
    const { name, value } = event.target;
    setState({
      ...State,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  function submithandler(e) {
    e.preventDefault();
    setisdisable(true);
    sendBtn.current.innerHTML = "Sending...";
    dispatch(sendEmail(State));
  }
  // sent email response
  const userDetail = useSelector((state) => state.emailSend);
  const { response, error } = userDetail;

  // error/sucess message and form resetting
  useEffect(() => {
    if (response) {
      setState({ name: "", email: "", subject: "", message: "" });
      setisdisable(false);
      sendBtn.current.innerHTML = "Send Message";
      popup({ title: response ? response.message : "" });
    } else if (error) {
      setisdisable(false);
      sendBtn.current.innerHTML = "Send Message";
      popup({ title: response ? response.message : "" });
    }
  }, [response, error]);
  return (
    <div className="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">Contact Us</h1>
        </div>
      </div>
      <div class="page-content">
        <div class="container">
          <div
            class="page-header page-header-big text-center"
            style={{
              backgroundImage:
                "url('https://res.cloudinary.com/dujjhexjj/image/upload/w_0.7,c_scale/v1671438283/islaheaam/map_ftxu91.png')",
            }}
          ></div>
        </div>

        <div class="container">
          <div class="row mt-4">
            <div class="col-md-4">
              <div class="contact-box text-center">
                <h3>Office</h3>

                <address>
                  Mujahida Patti Bhaguwala <br />
                  Bijnor, UP (246749)
                </address>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-box text-center">
                <h3>Start a Conversation</h3>

                <div>
                  <a href="mailto:Islaheaamintercollege01@gmail.com">
                    Islaheaamintercollege01@gmail.com
                  </a>
                </div>
                <div>
                  <a href="tel:9917164462">+91 9917164462</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-box text-center">
                <h3>Social</h3>

                <div class="social-icons social-icons-color justify-content-center">
                  <a
                    href="https://www.facebook.com/IslaheaamInterCollege"
                    class="social-icon social-facebook"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="icon-facebook-f"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/islaheaamintercollege/"
                    class="social-icon social-instagram"
                    title="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="icon-instagram"></i>
                  </a>
                  <a
                    href="https://youtube.com/@islaheaamintercollege"
                    class="social-icon social-youtube"
                    title="Youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="icon-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-3 mb-5 mt-md-1" />
          <div class="touch-container row justify-content-center">
            <div class="col-md-9 col-lg-7">
              <div class="text-center">
                <h2 class="title mb-4">Get In Touch</h2>
              </div>

              <form onSubmit={submithandler} class=" mb-3">
                <div class="row">
                  <div class="col-sm-6">
                    <label for="cname" class="sr-only">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cname"
                      placeholder="Name *"
                      name="name"
                      value={State.name}
                      onChange={Inputchange}
                      required
                    />
                  </div>

                  <div class="col-sm-6">
                    <label for="cemail" class="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="cemail"
                      placeholder="Email"
                      name="email"
                      value={State.email}
                      onChange={Inputchange}
                    />
                  </div>
                </div>

                {/* <div class="row"> */}
                {/* <div class="col-sm-6">
              <label for="cphone" class="sr-only">
                Phone
              </label>
              <input
                type="tel"
                class="form-control"
                id="cphone"
                placeholder="Phone"
              />
            </div> */}

                {/* <div class="col-sm-6"> */}
                <div class="row">
                  <div class="col-sm-6">
                    <label for="csubject" class="sr-only">
                      Subject
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      placeholder="Subject *"
                      name="subject"
                      value={State.subject}
                      onChange={Inputchange}
                      required
                    />
                  </div>
                  <div class="col-sm-6">
                    <label for="csubject" class="sr-only">
                      Mobile No.
                    </label>
                    <input
                      type="tel"
                      pattern="^\d{10}"
                      class="form-control"
                      id="mobile"
                      placeholder="Enter 10 digit mobile no. *"
                      name="mobile"
                      value={State.mobile}
                      onChange={Inputchange}
                      required
                    />
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}

                <label for="cmessage" class="sr-only">
                  Message
                </label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="4"
                  id="cmessage"
                  required
                  placeholder="Message *"
                  name="message"
                  value={State.message}
                  onChange={Inputchange}
                ></textarea>

                <button
                  type="submit"
                  ref={sendBtn}
                  disabled={isdisable}
                  class="btn btn-primary btn-minwidth-sm"
                >
                  <span>Send Message</span>
                  <i class="icon-long-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
